<template>
	<div v-if="items.length" v-click-outside="closeLangNav" class="lang-select" @click="toggleLangNav">
		<span>{{ locale }}&nbsp;</span>
		<font-awesome-icon icon="fa-light fa-chevron-down" size="1x" />
		<div v-if="showLangNav" class="lang-wrapper">
			<div v-for="item in items" :key="`${item.filename}-${item.language}`">
				<nuxt-link class="flag" :class="item.language" :to="`${item.filename}`" @click="hideMenu">
					{{ item.language }}
				</nuxt-link>
			</div>
		</div>
	</div>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	items: { type: Array, default: () => [] },
});

const showLangNav = ref(false);

const toggleLangNav = () => {
	showLangNav.value = !showLangNav.value;
};

const closeLangNav = () => {
	showLangNav.value = false;
};
</script>

<style lang="scss" scoped>
.lang-select {
	max-width: 75px;
	padding: 12px 15px;
	cursor: pointer;
	position: relative;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	font-size: 14px;

	svg {
		font-size: 12px;
		margin: 0 0 0 5px;
	}
}

.lang-wrapper {
	position: absolute;
	top: 35px;
	left: 3px;
	right: 3px;
	padding: 5px 9px;
	text-align: center;
	background: #fff;
	box-shadow: 0 18px 18px rgba(0 0 0 / 10%);
	border-radius: 3px;
	z-index: 90;
}

.lang-wrapper.active,
.lang-wrapper a {
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	place-content: space-between flex-start;
	color: #000;
	gap: 6px;
}

.lang-wrapper a {
	width: 100%;
	flex-direction: row;
	margin: 6px 0;
}
</style>
